.Toastify {
  .Toastify__toast-container {
    .Toastify__toast--error {
      background: #f3d3d3;
      button.Toastify__close-button {
        color: #000;
        opacity: 0.3;
      }
    }
  }
}
.common-toast-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  color: #000000;
  // position: fixed;
  // top: 0;
  // left: 0;
  width: 100%;
  // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
  color: #000; /* White text color */
  text-align: center; /* Centered text */
  border-radius: 2px; /* Rounded borders */
  padding: 16px 2px; /* Padding */
  z-index: 50;
  font-family: adobe-garamond-pro, serif;
  .close-container {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 3px;
    z-index: 51;
  }
  span {
    display: flex;
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
    line-height: 1.55;
    font-family: adobe-garamond-pro, serif;
    a {
      color: #000000;
      margin-left: 6px;
      margin-right: 6px;
    }
  }
  &.error {
    color: #c63232;
    background: #f3d3d3;
    span {
      font-family: termina,sans-serif;
      font-size: 10px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: center;
    }
  }
  .with-action-link-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 2px 0px 2px;
    align-items: center;
    .message-container {
      width: 80%;
      display: block;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-content: flex-start;
      span.message {
        display: contents;
        word-break: break-all;
      }
    }
    .action-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-content: center;
      align-items: center;
      justify-content: center;
      margin-top: 14px;
      a.action-label {
        display: block;
        float: right;
        font-family: termina,sans-serif;
        font-size: 10px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 1px;
      }
    }
  }
  .with-action-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0px 4px 0px 4px;
    .message-container {
      width: 80%;
      display: block;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-content: flex-start;
      span.message {
        display: contents;
        word-break: break-all;
      }
    }
    .action-container {
      width: 20%;
      display: flex;
      flex-direction: row;
      align-content: center;
      align-items: center;
      justify-content: center;
      span.action-label {
        display: block;
        float: right;
        font-family: termina,sans-serif;
        font-size: 10px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 1px;
      }
    }
  }
}
 // iPhone 5/SE
 @media only screen 
 and (min-device-width: 320px) 
 and (max-device-height: 568px) 
 and (-webkit-device-pixel-ratio: 2) {
     .with-action-container .message-container .fr {
         font-size: 12px;
     }
    }
