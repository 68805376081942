@import "../../common-style/variables";

* {
    box-sizing: border-box;
}

// Offline Error Screen CSS
.body.offline {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
  }
  .body.offline img {
    width: 157px;
  }
  .offline h3 {
    opacity: 0.5;
    font-family: Lato;
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: 0.45px;
    color: #000000;
    margin-bottom: 10px;
  }
  .offline label {
    opacity: 0.5;
    font-family: Lato;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.3px;
    text-align: center;
    color: #000000;
    width: 80%;
  }
////////////////////////////////////////////////////////////////////////////////

body {
    font-family: adobe-garamond-pro, serif;
    font-weight: 400;
    font-style: normal;
}

.app-layout {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    .zh {
        font-family: source-han-sans-simplified-c, sans-serif !important;
        font-style: normal;
        font-weight: 100;
    }
    .scanner-wrapper {
        &.hidden{
            display: none
        }
    }
    &.zh .bottom-navigation-bar .navigation-item label{
        font-size: 12px;
    }
    .preloader-container {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        left: 0px;
        top: 0px;
        height: 100vh;
        width: 100vw;
        z-index: 200;
        .overlay {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0px;
            left: 0px;
            z-index: 10;
            background: #ffffff;
            opacity: 1;
        }
        .preloader {
            position: relative;
            z-index: 11;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 6rem;
            height: 6rem;
            background: #fff;
            border-radius: 1rem;
            img {
                width: 2rem;
            }
        }
    }
}