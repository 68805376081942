.preloader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100vh;
    width: 100vw;
    z-index: 200;
    .overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 10;
        background: #ffffff;
        opacity: 1;
    }
    .preloader {
        position: relative;
        z-index: 11;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 6rem;
        height: 6rem;
        background: #fff;
        border-radius: 1rem;
        img {
            width: 2rem;
        }
    }
    &.section-loader {
        .overlay {
            background: transparent !important;
        }
        .preloader {
            width: 3rem !important;
            height: 3rem !important;
            background: #fff !important;
            border-radius: 1rem !important;
            box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.3) !important;
        }
    }
}