@import "../../common-style/variables";

button.coach-main-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $deep-black;
    color: #ffffff;
    font-size: 10px;
    line-height: 16px;
    text-transform: uppercase;
    outline: none;
    border: 0px;
    font-family: termina,sans-serif;
    font-weight: 500;
    letter-spacing: 1px;
    font-style: normal;

    &.short {
        min-height: 50px;
        min-width: 245px;
    }

    &.long {
        height: 3.2rem;
        width: 100%;
    }

    &.line {
        background: transparent;
        color: #000000;
        border: 1px solid #000000;
    }
    &.disabled {
        opacity: 0.15;
        cursor: default;
    }
    img {
        width: 32px;
        height: 32px;
        margin-right: 8px;
    }
}
.zh button.coach-main-button {
    font-size: 16px;
}