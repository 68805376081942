.overlay-transperant {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: rgba(0, 0, 0, 0.5);
}
.language-selector-container-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    left: 0px;
    background: #ffffff;
    z-index: 125;
    width: 100%;
    padding: 15px;
    border-top: solid 1px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 5px 17px 1px rgba(0, 0, 0, 0.15);
    transition: all .3s ease-out;
    &.hide {
        bottom: -100%;
    }
    &.show {
        bottom: 60px;
    }
    img {
        top: 10px;
        right: 10px;
        z-index: 12;
        position: absolute;
    }
    .heading {
        font-family: adobe-garamond-pro, serif;
        font-size: 21px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: center;
        color: #000000;
        margin-bottom: 20px;
    }
    .list {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .item {
            display: block;
            margin-bottom: 10px;
            padding: 9px 0px;
            width: 140px;
            text-align: center;
            &:last-child {
                margin-bottom: 0px;
            }
            &.selected {
                border-radius: 23px;
                border: solid 1px rgba(0, 0, 0, 0.5);
            }
        }
    }
}
// iphone x
@media only screen 
    and (device-width : 375px) 
    and (device-height : 812px) 
    and (-webkit-device-pixel-ratio : 3) {
        .language-selector-container-box.show {
            bottom: 75px;
        }
    }