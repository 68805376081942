@import '../../common-style/variables';

.scan-permission-error-popup-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    overflow: auto;
    background: #ffffff;
    z-index: 130;
    .landing-logo {
        margin-top: 50px;
        margin-bottom: 40px;
    }
    .title {
        font-size: 24px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: center;
        &.last {
            margin-bottom: 44px;
        }
    }
    .list-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 315px;
        .item {
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.25;
            &.info {
                margin-bottom: 20px;
            }
        }
    }
}