body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  outline: none;
}
i {
  font-family: adobe-garamond-pro, serif;
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'GothicNo13BT';
  src: local('GothicNo13BT'), url(./static/fonts/Knockout-48_Web.woff2) format('woff2'),
                              url(./static/fonts/Knockout-48_Web.woff) format('woff');
}