.coach-link {
    font-weight: 400;
    font-size: 1rem;
    position: relative;
    &::after {
        content: '';
        width: 100%;
        border-bottom: 1px solid #000000;
        position: absolute;
        bottom: 0px;
        left: 0px;
    }
}

.fr .coach-link {
    font-size: 16px;
}
.zh .coach-link {
    font-size: 16px;
}