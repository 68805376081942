.full-screen-modal-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 150;
    background: #ffffff;
    flex-direction: column;
    img.close {
        position: absolute;
        top: 24px;
        right: 24px;
        z-index: 145;
        height: 32px;
    }
    .content-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        flex-direction: column;
        height: 100%;
        overflow-y: auto;
        .carousel .slider-wrapper.axis-horizontal .slider .slide {
            background: transparent;
        }
    }
}