.bottom-navigation-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 55px;
    background: #ffffff;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 126;
    border-top: solid 1px rgba(0, 0, 0, 0.15);
    .navigation-item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20%;
        height: 100%;
        flex-direction: column;
        //reset button styling
        -webkit-appearance: none;
        border: none;
        background-color: transparent;
        outline: none;
        z-index: 130;
        .icon-container {
            width: 32px;
            position: relative;
            span {
                position: absolute;
                left: 0px;
                top: 10px;
                text-align: center;
                width: 100%;
                z-index: 10;
                font-family: termina,sans-serif;
                font-size: 10px;
            }
            img {
                width: 100%;
            }
            &.selected {
                span {
                    color: #ffffff;
                }
            }
        }
        label {
            font-family: termina,sans-serif;
            font-size: 8px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.88;
            letter-spacing: 0.8px;
            text-align: center;
            color: #000000;
            text-transform: uppercase;
        }
    }
    &:focus {
        outline: none;
    }
}
// iphone x
@media only screen 
    and (device-width : 375px) 
    and (device-height : 812px) 
    and (-webkit-device-pixel-ratio : 3) {
        .bottom-navigation-bar {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-start;
            height: 70px;
            background: #ffffff;
            position: fixed;
            bottom: 0px;
            left: 0px;
            width: 100%;
            z-index: 126;
            border-top: solid 1px rgba(0, 0, 0, 0.15);
            .navigation-item {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 25%;
                height: 100%;
                flex-direction: column;
                padding-top: 6px;
                z-index: 130;
            }
        }
    }