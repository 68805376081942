.hamburger-menu-container {
    // width: 230px;
    height: 50%;
    position: fixed;
    z-index: 10;
    top: 200px;
    right: -332px;
    // background: #ffffff;
    // box-shadow: -10px 1px 30px #999;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    &.visible {
      right: 0px;
    }
    .feedbackButton{
        font-family: adobe-garamond-pro, serif;
        background-color: #000000;
        width: 33px;
        height:100px;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            width: 40px;
            height: 40px;
            position: absolute;
            top: 0px;
            right: 290px;
        }
        span{
            color: #ffffff;
            transform: rotate(270deg);
            -ms-transform: rotate(270deg);
            justify-content: center;
            align-items: center;
            display: flex;
            font-size: 20px;
        }
    }
    iframe {
        box-shadow: 20px 5px 15px 15px #999;
        width: 330px;
        height: 100%;
    }
}