@import '../../common-style/variables';
// Hiding play button inside video element
.media-controls-container {
    display: none !important;
}
// // // // // // // 
.product-barcode-scan-error {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background-color: #ffffff;
    z-index: 120;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: auto;
    &.hidden {
        display: none;
    }
    img.close-icon {
        position: absolute;
        right: 0px;
        top: 0px;
        padding: 0.2rem;
        z-index: 104;
    }
    h2 {
        font-weight: 400;
        font-size: 2rem;
        margin-top: 0px;
        margin-bottom: 3rem;
    }
    img.error-image {
        width: 27%;
    }
    .coach-link {
        &.last {
            margin-top: 20px;
        }
    }
    label {
        // margin-top: 2rem;
        font-size: 1.1rem;
        font-weight: 400;
        margin-bottom: 1rem;
        &.first {
            margin-bottom: 0px;
        }
        &.last {
            margin-top: 0px;
        }
    }
    .bottom-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        bottom: 2.5rem;
        left: 0px;
        width: 100%;
        position: unset;
        padding: 2.2rem 0;
        font-weight: 400;
    }
}
.enter-product-barcode {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: calc(100vh - 55px);
    background-color: #ffffff;
    z-index: 121;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &.hidden {
        display: none;
    }

    img.close-icon {
        position: absolute;
        right: 0px;
        top: 0px;
        padding: 0.2rem;
        z-index: 104;
    }
    .info-label {
        font-size: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 74%;
        text-align: center;
    }
    .small-label {
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.88;
        letter-spacing: normal;
        text-align: center;
    }
    img.barcode-image {
        width: 45%;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    .action-container {
        display: flex;
        width: 60%;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;
    }
}
.scan-product-barcode {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: calc(100% - 55px);
    background-color: #000000;
    z-index: 120;
    &.hidden {
        display: none;
    }

    .mask-contents {
        width: 100%;
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        top: 50%;
        transform: translateY(-40%);

        img {
            margin-bottom: 40%;
            width: 65px;
        }
        .help-text {
            color: #fff;
            font-size: 21px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43;
            letter-spacing: normal;
            text-align: center;
            &.last {
                margin-bottom: 27px;
            }
        }
    }

    .full-screen-modal-container {
        background: #e8e5de;
        font-family: adobe-garamond-pro, serif;
        font-weight: 400;
        font-style: normal;
        &.termsofuse {
            background: #ffffff;
            .content-container {
                justify-content: flex-start;
                align-items: flex-start;
            }
        }
    }
    .terms-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        p {
            width: 100%;
            height: 100vh;
            overflow-y: auto;
            padding: 0px 24px;
            padding-bottom: 134px;
            a {
                color: inherit;
            }
        }
        h2 {
            margin-top: 50px;
            margin-block-end: 0px;
            font-size: 28px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.61;
            letter-spacing: normal;
            text-align: center;
        }
    }
    .header-container {
        position: absolute;
        width: 100%;
        background-color: #fff;
        height: 50px;
        z-index: 104;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;

        span.header-label {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: center;
            .main-link{
                font-size: 14px;
            }
        }
    }
    .toast-container {
        position: absolute;
        z-index: 150;
        padding: 2rem;
        width: 100%;
        background: #ffffff;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        border-bottom: 1px solid #979797;
        box-shadow: 1px -12px 41px 1px #999;
        span {
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.25;
            letter-spacing: 0.3px;
            text-align: center;
        }
    }
    img.close-icon {
        position: absolute;
        right: 0px;
        top: 50px;
        padding: 0.2rem;
        z-index: 104;
        width: 45px;
    }
    #shadow-dom-mask {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 60%;
        background: #000;
        z-index: 100;
        display: none;
    }
    .mask {
        position: absolute;
        left: 0px;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.3);
        z-index: 103;
        clip-path: polygon(0 0, 0% 100%, 19.7% 100%, 18.7% 42.5%, 81.4% 42.5%, 81.4% 57.5%, 19% 57.5%, 18% 100%, 100% 100%, 100% 0%);
    }
    #scan-camera {
        .scandit {
            .scandit-barcode-picker {
                img {
                    &.scandit-logo {
                        transform: rotate(270deg) translateY(-50%);
                        top: 50%;
                        right: -5%;
                        width: 14%;
                    }
                }
            }
        }
    }
    .scan-camera {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        div {
            height: 100%;
        }
        video::shadow div {
            display: none !important;
        }
        video {
            height: 100%;
            width: 100%;
            object-fit: cover;
            &::-webkit-media-controls-panel {
                display: none !important;
            }
            &::-webkit-media-controls-play-button {
                display: none !important;
            }
            &::-webkit-media-controls-start-playback-button {
                display: none !important;
            }
            &::-webkit-media-controls {
                display: none !important;
            }
            .media-controls-container {
                display: none !important;
            }
        }
    }
    .camera-label {
        position: absolute;
        z-index: 102;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        color: #fff;
        &.heading {
            top: 10vh;
            font-size: 24px;
            text-shadow: 0 0 10px #000;
            padding: 0px 6px;
            text-align: center;
        }
        &.action-link {
            text-decoration: underline;
            bottom: 23vh;
        }
    }
    .target-square {
        display: flex;
        width: 63%;
        height: 15%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 102;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .innerblock {
            display: flex;
            width: 100%;
            height: 100px;
        }
        .bottom {
            &::after {
                content: '';
                position: absolute;
                bottom: 0px;
                left: 0px;
                width: 30px;
                height: 30px;
                border-bottom: 3px solid #fff;
                border-left: 3px solid #fff;
            }
            &::before {
                content: '';
                position: absolute;
                bottom: 0px;
                right: 0px;
                width: 30px;
                height: 30px;
                border-bottom: 3px solid #fff;
                border-right: 3px solid #fff;
            }
        }
        .top {
            &::after {
                content: '';
                position: absolute;
                top: 0px;
                left: 0px;
                width: 30px;
                height: 30px;
                border-top: 3px solid #fff;
                border-left: 3px solid #fff;
            }
            &::before {
                content: '';
                position: absolute;
                top: 0px;
                right: 0px;
                width: 30px;
                height: 30px;
                border-top: 3px solid #fff;
                border-right: 3px solid #fff;
            }
        }
        
    }
    .target-square-bottom {
        display: flex;
        width: 75%;
        height: 50px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 102;
        &::after {
            content: '';
            position: absolute;
            top: 0px;
            left: 0px;
            width: 45px;
            height: 45px;
            border-top: 3px solid #fff;
            border-left: 3px solid #fff;
        }
        &::before {
            content: '';
            position: absolute;
            top: 0px;
            right: 0px;
            width: 45px;
            height: 45px;
            border-top: 3px solid #fff;
            border-right: 3px solid #fff;
        }
    }
    .gradient-block {
        background-image: linear-gradient(to bottom, rgba(238, 238, 238, 0), rgba(0, 0, 0, 0.8));
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 29vh;
        z-index: 101;
    }
    .entermanually-container {
        background: rgba(0, 0, 0, 0.6);
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50px;
        z-index: 104;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .enter-manually-link {
            color: #fff;
            font-size: 10px;
            font-family: termina,sans-serif;
            text-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 2;
            letter-spacing: 0.19px;
        }
    }
}

@media screen and (orientation:landscape) {
    .scan-product-barcode .scan-camera video {
        width: 100%;
        height: auto;
    }
    .product-barcode-scan-error h2 {
        margin-top: 11rem;
    }
    .product-barcode-scan-error .bottom-text {
        padding: 3rem 0;
        margin-bottom: 6rem;
        position: relative;
        bottom: 0rem;
    }
}
@media (min-width:600px)  {
    .scan-product-barcode .scan-camera video {
        width: 100%;
        height: unset;
    }
}
// iphone x
@media only screen 
    and (device-width : 375px) 
    and (device-height : 812px) 
    and (-webkit-device-pixel-ratio : 3) {
        .scan-product-barcode {
            height: calc(100% - 70px) !important;
        }
        .enter-product-barcode {
            height: calc(100% - 65px) !important;
        }
    }